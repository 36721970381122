@font-face {
  /* 사용할 폰트의 이름 */
  font-family: "noto-sans";
  font-display: fallback;

  /* 
    src: 사용할 폰트의 위치 및 형식 
       Safari, Android, iOS => ttf
       Modern Browsers => woff
       IE6-IE8 => eot
    */
  src: url("./static/fonts/NotoSans-Medium.ttf") format("truetype"),
    url("./static/fonts/NotoSans-Medium.woff") format("woff"),
    url("./static/fonts/NotoSans-Medium.eot?iefix") format("embedded-opentype");

  /* 사용할 폰트의 기본 스타일 */
  font-weight: 500;
  font-style: Medium;
}
