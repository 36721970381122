.Scroll-Container {
  width: 100%;
  height: 100vh;
  overflow: hidden auto;
  scroll-snap-type: y mandatory;
}
.Video-Item {
  scroll-snap-align: start;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
