@import "reset.css";

.App {
  text-align: center;
  background-color: black;
}

span {
  font-family: "noto-sans";
}

.Header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.Nav-Box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Nav_Button {
  padding: 4px 24px 4px 24px;
  font-size: 18px;
  letter-spacing: 0.8px;
  border-radius: 100px;
  border: 0px;
  color: white;
  background-color: black;
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Nav_Button:hover {
  background-color: white;
  color: black;
  transition: 160ms ease-in-out;
}

.Nav_logo {
  margin-left: 40px;
  height: 36px;
}
.Nav_logo_mobile {
  height: 24px;
}

.Nav_Button_Mobile {
  padding: 4px 36px 4px 36px;
  font-size: 18px;
  letter-spacing: 0.8px;
  border-radius: 100px;
  border: 0px;
  color: black;
  background-color: white;
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -4px;
}
.Nav_Button_Mobile:hover {
  background-color: white;
  color: black;
  transition: 160ms ease-in-out;
}
.Nav-Box-Mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Nav_Icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  margin-right: 40px;
}

.Nav_Icon_Image {
  height: 100%;
  background-color: black;
  padding: 4px;
  border-radius: 8px;
}
.Nav_Icon_Image:hover {
  background-color: white;
  transition: 160ms ease-in-out;
}
.Mobile-Menu {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.205); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.Mobile-Menu-Item {
  width: 40%;
  /* margin-right: ; */
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Mobile-Menu-Empty {
  width: 60%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.666);
  opacity: 0;
}
.Mobile-Menu-Empty-In {
  opacity: 1;
  transition: 1000 ease-in-out;
}

.Mobile-Menu-Position {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
}
.Mobile-Nav {
  width: 100%;
  /* background-color: red; */
  padding: 0px 20px 0px 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Mobile-Nav-Text {
  font-size: 16px;
}

.Mobile-Nav-Text {
  font-size: 16px;
}
.Mobile-Nav-Text-Touch {
  font-size: 16px;
  color: black;
}

.Nav_Icon_Mobile {
  width: 24px;
  height: 24px;
}
.Mobile-Menu-Close {
  /* position: fixed; */
  top: 10%;
  width: 100%;
  height: 10%;
  color: white;
  /* background-color: red; */
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingPosition {
  position: absolute;
}
