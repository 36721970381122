.Title {
  color: white;
}
input {
  font-family: "noto-sans";
  font-size: 12px;
  width: 300px;
  color: white;
  border-bottom: 1px solid gray;
  margin-left: 10px;
  text-align: center;
}
input:focus {
  outline: 0;
  border-bottom: 1px solid yellow;
  transition: 160ms ease-in-out;
}
input:hover {
  background-color: white;
  color: black;
  transition: 160ms ease-in-out;
}
span {
  color: white;
  font-size: 12px;
}

.Input-Box {
  width: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  align-items: center;
  /* justify-content: center; */
}
.Input-Item {
  display: flex;
  width: 400px;
  justify-content: space-between;
  margin-bottom: 30px;
}
.Input-Label {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Contact-Input-Top {
  margin-bottom: 4px;
}

textarea {
  background-color: white;
  width: 400px;
  min-height: 120px;
  max-height: 200px;
  padding-top: 10px;
  text-indent: 10px;
  border-radius: 8px;
}
textarea:focus {
  outline: 0;
  border-bottom: 1px solid yellow;
}
.Submit {
  border-radius: 8px;
  width: 200px;
  height: 60px;
  background-color: white;
  align-self: center;
  margin-top: 20px;
  color: black;
  font-family: "noto-sans";
  font-weight: 800;
}
.Submit:hover {
  transition: 100ms ease-in-out;
  background-color: black;
  color: white;
}

.ModalItem {
  width: 500px;
  height: 180px;
  background-color: black;
  color: white;
  /* border: 1px solid rgba(255, 255, 255, 0.652); */
  border-radius: 8px;
  margin-top: 160px;
  padding: 3px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(to bottom right, white 0%, white 56%, rgb(0, 0, 0) 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* line-height: 30px; */
}

.ModalText {
  font-size: 18px;
  margin-bottom: 5px;
}

.ModalClose {
  margin-top: 15px;
  width: 100px;
  height: 40px;
  background-color: black;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "noto-sans";
  padding-bottom: 2px;
  padding-left: 2px;
}

.ErrorModalText {
  font-size: 16px;
  margin-bottom: 20px;
}
