.Grid-Container {
  width: 100%;
  height: 100%;
}
.Grid-Column {
  display: grid;
  justify-content: center;
  grid-template-columns: 10% 25% 5% 25% 10%;
  margin-top: 50px;
}
.Grid-Column-Mobile {
  display: grid;
  justify-content: center;
  grid-template-columns: 2.5% 45% 5% 45% 2.5%;
  margin-top: 50px;
}
.Grid-Item {
  width: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  background-color: black;
  border-radius: 10px;
  /* border: 1px solid red; */
  padding-bottom: 1px;
  /* padding-right: 1px; */
  padding-left: 0.1px;
  background-image: linear-gradient(#000000, #000000),
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255) 0%,
      rgb(0, 0, 0) 80%,
      #e1ff1f 100%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.Grid-Overflow {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Title {
  width: 100%;
  height: 100px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Title_Mobile {
  width: 100%;
  height: 100px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subTitle {
  padding-top: 100px;
  font-size: 36px;
  color: red;
}
