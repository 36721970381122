* {
  padding: 0;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none;
}
.Safe-Area {
  position: fixed;
  width: 100%;
  height: 120px;
  background-color: black;
}
.Safe-Area2 {
  width: 100%;
  height: 120px;
}
.Safe-Area-Bottom {
  width: 100%;
  height: 100px;
  background-color: black;
}
header {
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 80px;
}
main {
  width: 100vw;
  height: auto;
  scroll-snap-type: proximity;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: none;
  flex-wrap: nowrap;
}

footer {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 100px;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-top: 1px solid white; */
  border-top: 1px solid;
  border-image: linear-gradient(
    to right,
    #ffffff 0%,
    #000000 30%,
    #000000 70%,
    #e1ff1f 100%
  );
  border-image-slice: 1;
}

.Footer_Logo {
  height: 64px;
  margin-left: 40px;
}
.Footer_Logo_Mobile {
  height: 32px;
  margin-left: 40px;
}

.Footer_Contact_Class {
  font-size: 12px;
  color: white;
  /* margin-right: 40px; */
  letter-spacing: 1.5px;
  margin-right: 16px;
}

.Footer_Contact_Name {
  font-size: 12px;
  color: white;
  margin-right: 13px;
  letter-spacing: 3px;
}
.Footer_Contact_Phone {
  font-size: 12px;
  color: white;
  letter-spacing: 2.95px;
  margin-bottom: 2px;
}
.Footer_Contact_Email {
  font-size: 12px;
  color: white;
  letter-spacing: 1.4px;
  margin-bottom: 6px;
}
.Footer_Slogun {
  font-size: 10px;
  color: white;
  letter-spacing: 2px;
  word-spacing: 3px;
}
.Footer_Contact_Class_Mobile {
  font-size: 9px;
  color: white;
  /* margin-right: 40px; */
  letter-spacing: 1.2px;
  margin-right: 5px;
}
.Footer_Contact_Name_Mobile {
  font-size: 9px;
  color: white;
  margin-right: 5px;
  letter-spacing: 2.8px;
}
.Footer_Contact_Phone_Mobile {
  font-size: 9px;
  color: white;
  letter-spacing: 2px;
  margin-bottom: 2px;
}
.Footer_Contact_Email_Mobile {
  font-size: 9px;
  color: white;
  letter-spacing: 0.9px;
  margin-bottom: 6px;
}
.Footer_Slogun_Mobile {
  font-size: 7px;
  color: white;
  letter-spacing: 1.5px;
  word-spacing: 3px;
}

video {
  scroll-snap-align: start;
  object-fit: cover;
}
.Video-Item {
  /* width: 100%; */
  height: 100vh;
  position: relative;
}
.About_Text {
  font-size: 12px;
  letter-spacing: 1px;
  word-spacing: 3.5px;
  line-height: 1.5;
  margin-bottom: 20px;
}
.About_Text_Mobile {
  font-size: 16;
  letter-spacing: 1px;
  word-spacing: 3.5px;
  line-height: 1.4;
  margin-bottom: 20px;
}
